table.no-row-border {
  thead {
    tr {
      th {
        border-bottom: 1px solid rgb(235, 237, 240);
      }
    }
  }
  tbody {
    tr {
      td {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
}

.listtable-0-52 {
  padding-left: 10px;
}

th :where(.sortable, .notSortable) {
  font-weight: 500;
}

th.sortable {
  cursor: pointer;
  white-space: nowrap;

  i {
    opacity: 0;
  }

  &:hover,
  &:focus,
  &:active {
    color: #627691;

    i {
      opacity: 1;
      color: #627691;
    }
  }
}

tr.hoverable {
  &:hover {
    background-color: #fafcff;
  }
}

th.notSortable {
  cursor: default;
  white-space: nowrap;
}

th.sortable.sorting {
  color: #627691;
  font-weight: 700;

  i {
    opacity: 1;
    color: #627691;
  }
}

[class*="list-table"] {
  isolation: isolate;

  thead {
    position: relative;
    z-index: 1;
  }

  tr {
    isolation: isolate;

    &:is(&:focus-within, &:focus, &:active) ~ tr {
      position: relative;
      z-index: -1;
    }
  }
}

.list-table {
  border-collapse: separate;

  th {
    position: sticky;
    top: 0;
    background-color: #fafcff;
    z-index: 90;
  }

  th,
  td {
    padding: 20px 10px 14px 0;
    font-weight: 500;
  }

  tr {
    th:first-child,
    td:first-child {
      padding-left: 20px;
    }
    th:last-child,
    td:last-child {
      padding-right: 20px;
    }
  }

  tr {
    background: transparent;
  }

  &__row {
    &.active td {
      background-color: rgba(#a8b6ca, 0.1);
    }

    &--clickable {
      cursor: pointer;
      &:hover td {
        background: rgba(#a8b6ca, 0.1);
      }
    }

    td {
      background: white;
      transition: background 0.1s;

      &:first-child {
        border-left: thin solid rgb(235, 237, 240);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-right: thin solid rgb(235, 237, 240);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  &__empty-row td {
    padding: 7px;
    border: 0;
  }
}

table.list-table-basic {
  tr {
    th {
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 0.75px;
      .icon {
        font-size: 10px;
        padding-left: 4px;
      }
    }
  }

  .ellipsis {
    display: table;
    table-layout: fixed;
    width: 92%;
    span {
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

table.list-table-basic tr th:first-child,
table.list-table-basic tr td:first-child {
  padding-left: 0px;
}

table.list-table-basic th {
  padding: 8px 10px 8px 0;
  height: auto;
}

table.list-table-basic td {
  padding: 12px 10px 12px 0;
}
